<template>
    <div class="mainContainer" @click="goToHospitalPage">
        <div class="hospitalNameAndLogo">
            <div class="hospitalLogo">
                <img :src="hospitalData.hospitalLogo" style="border-radius: inherit; max-width: 100%; max-height: 100%; object-fit: contain;"/>
            </div>
            <div class="hospitalName">
                <div style="font-weight: 500; font-size: 14px; text-align: left;">{{ hospitalData.name }}</div>
                <div style="color: #1467BF; font-size: 12px; font-weight: 400;">{{ getCount }}</div>
            </div>
        </div>
        <div style="display: flex; flex-direction: column; gap: 8px;">
            <div style="display: flex; flex-direction: row; gap: 8px;">
            <div style="font-size: 14px; font-weight: 600;">{{$t("Customer.OpdAppointments.Phone")}}: </div>
            <div style="font-size: 14px; font-weight: 400; color: #7C8287;text-align: left;">
                <span v-for="(num, index) in hospitalData.phoneNumbers" :key="index">{{ num }}, </span>
            </div>
        </div>
        <div style="display: flex; flex-direction: row; gap: 8px; justify-content: flex-start;">
                <div style="font-size: 14px; font-weight: 600;">{{$t("Customer.CartPage.Address")}}: </div>
                <div style="font-size: 14px; font-weight: 400; color: #7C8287; text-align: left;">{{ hospitalData.address }}</div>
        </div>
        </div>

        </div>
</template>

<script>
import BodyContainer from '../../../../templates/bodyContainer.vue';
import HeaderContainer from '../../../../templates/headerContainerOPDBooking.vue';
import mainContainer from '../../../../templates/mainContainer.vue';



export default {
  components: { mainContainer, BodyContainer, HeaderContainer },
    name: 'SingleHospitalComp',
    props: ['hospitalData'],
    data: function() {
        return {
            todayAppointment: [],
            pageLoading: true,
            ongoingAppointment: [],
            bannerURL: '',
        };
    },
    async mounted() {
    },
    computed: {
        getCount() {
            if(this.hospitalData.opdPackageFlow) {
                if(this.hospitalData.packageCount > 10) {
                    return `${this.hospitalData.packageCount} + Packages available` 
                } else {
                    return `${this.hospitalData.packageCount}  Packages available` 
                }
            } else {
                if(this.hospitalData.doctorCount > 10) {
                    return `${this.hospitalData.doctorCount} + doctors available` 
                } else {
                    return `${this.hospitalData.doctorCount}  doctors available` 
                }
            }
        }
    },
    methods: {
        goToHospitalPage() {
            if(this.hospitalData.opdPackageFlow == false) {
                this.$router.push({
                path: `/viewHospital/${this.hospitalData.hospitalRegNumber}`,
                });
            } else {
                this.$router.push({
                path: `package/viewHospital/${this.hospitalData.hospitalRegNumber}`,
                });
            }
        },
    },
};

</script>
<style  scoped>
.mainContainer {
    padding: 16px;
    background: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    cursor: pointer;
}
.headerHeading {
    color: white;
    font-weight: 600;
}
.hospitalName {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.hospitalLogo {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 56px;
    max-width: 56px;
    min-height: 56px;
    min-width: 56px;
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
}
img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.hospitalNameAndLogo {
    display: flex;
    align-items: center;
    gap: 16px;
}
</style>